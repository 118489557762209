<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-sm-6">
            <app-button-edit
              v-if="dataLoaded"
              route-name="printTitle_edit"
              :record-id="printTitle.id"
            >
            </app-button-edit>
          </div>
          <div class="col-sm-6">
            <div class="text-right m-r-5">
              <app-button-delete v-if="dataLoaded" @deleteRecord="deletePrintTitle"></app-button-delete>
              <app-button-close route-name="printTitle_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div v-if="dataLoaded" class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th>Id</th>
              <td>{{ printTitle.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('printTitle.title') }}</th>
              <td>{{ printTitle.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('printTitle.name') }}</th>
              <td v-html="printTitle.name"></td>
            </tr>
            <tr>
              <th>{{ $t('printTitle.site') }}</th>
              <td v-html="siteTitleById(printTitle.site)"></td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="printTitle"></app-detail-system-rows>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import DetailSystemRows from '../shared/DetailSystemRows'
import PrintTitleModel from '../../model/PrintTitle'
import ButtonEdit from '../shared/ButtonEdit'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import SiteService from '../../services/site/SiteService'

export default {
  name: 'PrintTitle',
  data () {
    return {
      printTitle: PrintTitleModel,
      dataLoaded: false
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonEdit: ButtonEdit,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows
  },
  methods: {
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    },
    getPrintTitle () {
      this.$store.dispatch('printTitle/fetchOne', this.$route.params.id)
        .then(() => {
          this.printTitle = this.$store.getters['printTitle/detail']
          this.dataLoaded = true
        })
    },
    deletePrintTitle () {
      this.$store.dispatch('printTitle/deleteRecord', this.printTitle)
        .then(() => {
          if (this.$store.getters['printTitle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/printTitle')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printTitle/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getPrintTitle()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
